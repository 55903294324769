<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Events Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Event Title">
                    <validation-provider
                      #default="{ errors }"
                      name="Event Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="event.title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Event Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Location">
                    <b-form-input
                      v-model="event.location"
                      placeholder="Event Location"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Organizer">
                    <b-form-input
                      v-model="event.organizer"
                      placeholder="Event Organizer"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Google Maps URL">
                    <b-form-input
                      v-model="event.directionUrl"
                      placeholder="Google Maps URL of the event location"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Event Address">
                    <b-form-textarea
                      placeholder="Event Address"
                      v-model="event.place"
                      rows="3"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Schedules">
          <b-row>
            <b-col class="text-center">
              <b-form-group label="Event Date">
                <b-calendar v-model="event.eventDate" block locale="en-US" />
              </b-form-group>
              <b-time v-model="event.startTime" locale="en" />
              <b-time class="ml-4" v-model="event.endTime" locale="en" />
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Gallery">
          <div class="mb-1 hotel-gallery" v-if="event.gallery.length > 0">
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in event.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeEventGalleryMedia(null, index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: null }"
            :selectedMediaFiles="event.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>
        <b-card title="Event Details">
          <b-row>
            <b-col cols="8">
              <b-form-group lable="Event Description">
                <quill-editor
                  v-model="event.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <h4>Thumbnail</h4>
              <div class="text-center my-1" v-if="event.thumbnailImg">
                <b-img v-bind="mainProps" :src="event.thumbnailImg"></b-img>
                <a
                  @click="removeThumbMedia(null)"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
                :isMultiple="false"
                :params="{ lang: null }"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <b-tab title="Hindi">
        <b-card title="Events Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Event Title">
                  <b-form-input
                    v-model="event.translation.hi.title"
                    placeholder="Event Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Location">
                  <b-form-input
                    v-model="event.translation.hi.location"
                    placeholder="Event Location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Organizer">
                  <b-form-input
                    v-model="event.translation.hi.organizer"
                    placeholder="Event Organizer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Google Maps URL">
                  <b-form-input
                    v-model="event.translation.hi.directionUrl"
                    placeholder="Google Maps URL of the event location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Event Address">
                  <b-form-textarea
                    placeholder="Event Address"
                    v-model="event.translation.hi.place"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Schedules">
          <b-row>
            <b-col class="text-center">
              <b-form-group label="Event Date">
                <b-calendar
                  v-model="event.translation.hi.eventDate"
                  block
                  locale="en-US"
                />
              </b-form-group>
              <b-time v-model="event.translation.hi.startTime" locale="en" />
              <b-time
                class="ml-4"
                v-model="event.translation.hi.endTime"
                locale="en"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Gallery">
          <div
            class="mb-1 hotel-gallery"
            v-if="event.translation.hi.gallery.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in event.translation.hi.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeEventGalleryMedia('hi', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: 'hi' }"
            :selectedMediaFiles="event.translation.hi.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>
        <b-card title="Event Details">
          <b-row>
            <b-col cols="8">
              <b-form-group lable="Event Description">
                <quill-editor
                  v-model="event.translation.hi.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <h4>Thumbnail</h4>
              <div
                class="text-center my-1"
                v-if="event.translation.hi.thumbnailImg"
              >
                <b-img
                  v-bind="mainProps"
                  :src="event.translation.hi.thumbnailImg"
                ></b-img>
                <a
                  @click="removeThumbMedia('hi')"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
                :isMultiple="false"
                :params="{ lang: 'hi' }"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>

      <b-tab title="Marathi">
        <b-card title="Events Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Event Title">
                  <b-form-input
                    v-model="event.translation.mr.title"
                    placeholder="Event Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Location">
                  <b-form-input
                    v-model="event.translation.mr.location"
                    placeholder="Event Location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Organizer">
                  <b-form-input
                    v-model="event.translation.mr.organizer"
                    placeholder="Event Organizer"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Google Maps URL">
                  <b-form-input
                    v-model="event.translation.mr.directionUrl"
                    placeholder="Google Maps URL of the event location"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Event Address">
                  <b-form-textarea
                    placeholder="Event Address"
                    v-model="event.translation.mr.place"
                    rows="3"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card title="Schedules">
          <b-row>
            <b-col class="text-center">
              <b-form-group label="Event Date">
                <b-calendar
                  v-model="event.translation.mr.eventDate"
                  block
                  locale="en-US"
                />
              </b-form-group>
              <b-time v-model="event.translation.mr.startTime" locale="en" />
              <b-time
                class="ml-4"
                v-model="event.translation.mr.endTime"
                locale="en"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Gallery">
          <div
            class="mb-1 hotel-gallery"
            v-if="event.translation.mr.gallery.length > 0"
          >
            <div
              class="gallery-item"
              :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
              v-for="(media, index) in event.translation.mr.gallery"
              :key="media.key"
            >
              <div class="actions">
                <b-button
                  size="sm"
                  @click="removeEventGalleryMedia('mr', index)"
                  variant="gradient-danger"
                  class="btn-icon"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
              <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
            </div>
          </div>
          <ComponentMediaSelector
            :params="{ lang: 'mr' }"
            :selectedMediaFiles="event.translation.mr.gallery"
            @mediaSelected="handleMediaSelect"
          />
        </b-card>
        <b-card title="Event Details">
          <b-row>
            <b-col cols="8">
              <b-form-group lable="Event Description">
                <quill-editor
                  v-model="event.translation.mr.description"
                  :options="snowOption"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <h4>Thumbnail</h4>
              <div
                class="text-center my-1"
                v-if="event.translation.mr.thumbnailImg"
              >
                <b-img
                  v-bind="mainProps"
                  :src="event.translation.mr.thumbnailImg"
                ></b-img>
                <a
                  @click="removeThumbMedia('mr')"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
                :isMultiple="false"
                :params="{ lang: 'mr' }"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-button @click="updateEvent" variant="primary" block size="lg"
        >Update Event</b-button
      >
    </b-card>
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BCalendar,
  BTime,
  BFormTextarea,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    BButton,
    BCalendar,
    BTime,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    quillEditor,
  },
  data() {
    return {
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      mainProps: {
        width: 100,
        height: 100,
      },
      eventID: "",
      event: {
        title: "",
        eventDate: "",
        thumbnailImg: "",
        location: "",
        directionUrl: "",
        place: "",
        organizer: "",
        startTime: "",
        endTime: "",
        description: "",
        gallery: [],
        translation: {
          hi: {
            title: "",
            eventDate: "",
            thumbnailImg: "",
            location: "",
            directionUrl: "",
            place: "",
            organizer: "",
            startTime: "",
            endTime: "",
            description: "",
            gallery: [],
          },
          mr: {
            title: "",
            eventDate: "",
            thumbnailImg: "",
            location: "",
            directionUrl: "",
            place: "",
            organizer: "",
            startTime: "",
            endTime: "",
            description: "",
            gallery: [],
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.event.translation[mediaObject.params.lang].gallery =
            mediaObject.media;
        } else {
          this.event.gallery = mediaObject.media;
        }
      }
    },
    handleThumbMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if (mediaObject.params.lang) {
          this.event.translation[mediaObject.params.lang].thumbnailImg =
            mediaObject.media.src;
        } else {
          this.event.thumbnailImg = mediaObject.media.src;
        }
      }
    },
    removeThumbMedia(lang = undefined) {
      if (lang) {
        this.event.translation[lang].thumbnailImg = "";
      } else {
        this.event.thumbnailImg = "";
      }
    },
    removeEventGalleryMedia(lang = undefined, index) {
      if (lang) {
        this.event.translation[lang].gallery.splice(index, 1);
      } else {
        this.event.gallery.splice(index, 1);
      }
    },
    updateEvent() {
      this.$refs.basicValidation.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/events/update`,
              { updates: this.event, id: this.eventID },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been updated successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while updating the event!"
              );
              console.log(error.response);
            });
        } else {
          this.makeToast(
            "danger",
            "Error",
            "Please fix the errors to continue."
          );
        }
      });
    },
    getEvent() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/events/cms/getEventById`, {
          id: this.$route.params.eventID,
        })
        .then((response) => {
          const {
            _id: eventID,
            title,
            eventDate,
            thumbnailImg,
            location,
            directionUrl,
            place,
            organizer,
            startTime,
            endTime,
            description,
            gallery,
            translation,
          } = response.data;
          this.event = {
            title,
            eventDate,
            thumbnailImg,
            location,
            directionUrl,
            place,
            organizer,
            startTime,
            endTime,
            description,
            gallery,
            translation: {
              hi: {
                title:
                  translation && translation.hi.title
                    ? translation.hi.title
                    : "",
                eventDate:
                  translation && translation.hi.eventDate
                    ? translation.hi.eventDate
                    : "",
                thumbnailImg:
                  translation && translation.hi.thumbnailImg
                    ? translation.hi.thumbnailImg
                    : "",
                location:
                  translation && translation.hi.location
                    ? translation.hi.location
                    : "",
                directionUrl:
                  translation && translation.hi.directionUrl
                    ? translation.hi.directionUrl
                    : "",
                place:
                  translation && translation.hi.place
                    ? translation.hi.place
                    : "",
                organizer:
                  translation && translation.hi.organizer
                    ? translation.hi.organizer
                    : "",
                startTime:
                  translation && translation.hi.startTime
                    ? translation.hi.startTime
                    : "",
                endTime:
                  translation && translation.hi.endTime
                    ? translation.hi.endTime
                    : "",
                description:
                  translation && translation.hi.description
                    ? translation.hi.description
                    : "",
                gallery:
                  translation && translation.hi.gallery
                    ? translation.hi.gallery
                    : [],
              },
              mr: {
                title:
                  translation && translation.mr.title
                    ? translation.mr.title
                    : "",
                eventDate:
                  translation && translation.mr.eventDate
                    ? translation.mr.eventDate
                    : "",
                thumbnailImg:
                  translation && translation.mr.thumbnailImg
                    ? translation.mr.thumbnailImg
                    : "",
                location:
                  translation && translation.mr.location
                    ? translation.mr.location
                    : "",
                directionUrl:
                  translation && translation.mr.directionUrl
                    ? translation.mr.directionUrl
                    : "",
                place:
                  translation && translation.mr.place
                    ? translation.mr.place
                    : "",
                organizer:
                  translation && translation.mr.organizer
                    ? translation.mr.organizer
                    : "",
                startTime:
                  translation && translation.mr.startTime
                    ? translation.mr.startTime
                    : "",
                endTime:
                  translation && translation.mr.endTime
                    ? translation.mr.endTime
                    : "",
                description:
                  translation && translation.mr.description
                    ? translation.mr.description
                    : "",
                gallery:
                  translation && translation.mr.gallery
                    ? translation.mr.gallery
                    : [],
              },
            },
          };
          this.eventID = eventID;
        })
        .catch((error) => {
          console.log(response.data);
        });
    },
  },
  mounted() {
    this.getEvent();
  },
};
</script>

<style lang="scss">
.hotel-gallery {
  display: flex;
  .gallery-item {
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
</style>